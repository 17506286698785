import Sidebar from "../../components/editorComponents/Sidebar";
import ServicesSidebar from "./Sidebar/index";
import { LayoutData } from "rc-dock";
import { EditorPage, UiEditor, WrapperSettingsWidget } from "./UiEditor";
import ApplicationApi from "../../api/ApplicationApi";
import React, { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../reducers";
import {
  AddNodeRed,
  NodeRedCollapse,
  WrapperCollapse,
} from "./Explorer/NodeRedCollapse";
import { IframeCollapse } from "./Explorer/IframeCollapse";
import { getTransformer } from "../../components/editorComponents/transformersMenu";
import TransformEditor from "../../components/editorComponents/TransformEditor";
import TransformEditorJS from "../../components/editorComponents/TransformEditorJS";
import styled from "styled-components";
import DebuggerTabs from "../../components/editorComponents/Debugger/DebuggerTabs";
import { UiEditorCollapse } from "./Explorer/UiEditorCollapse";
import { DbCollapse } from "./Explorer/dbCollapse";
import { ReactComponent as Update } from "../../assets/images/work_space/update.svg";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import UbosLoader2 from "pages/common/UbosLoader2";
import { useLocation } from "react-router-dom";
import { QUERY_KEY_TEMPLATE_ID } from "constants/routes";
import { getCurrentUser } from "selectors/usersSelectors";
import { getURLForCustomTitleTab } from "./MainContainer";
import { Icon, Tooltip } from "@blueprintjs/core";
import { ReactComponent as MainLogo } from "../../assets/images/logo/fullLogo.svg";
import { ReactComponent as TemplateIcon } from "../../assets/images/work_space/templateIcon.svg";
import { ReactComponent as WorkspaceIcon } from "../../assets/images/work_space/SideIcon.svg";
import { ReactComponent as UserIcon } from "../../assets/images/work_space/user.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/control/info-circle-icon.svg";
import { ReactComponent as GameIcon } from "../../assets/icons/control/game-logo.svg";

const WrapperGlobalSidebar = styled.div`
  background: #f3f3f3;
  height: 100%;
  width: 100%;
  color: white;
  position: relative;
  z-index: 4;
  .wrapper_header {
    display: flex;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    height: 32px;
    color: #1e272e;
    justify-content: space-between;
    align-items: center;
    .title {
      width: 69px;
      margin-left: 18px;
      font-weight: 600;
    }
    .wrapper_button {
      padding: 0 0 0 25px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100px;
      svg: hover {
        cursor: pointer;
      }
    }
    .wrapper_button_servisec {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 10px;
      width: 50px;
      svg: hover {
        cursor: pointer;
      }
    }
  }
`;

const AddServiceBtn = styled.button`
  border: none;
  background-color: #3b82ff;
  padding: 2px 5px;
  border-radius: 4px;
  display: flex;
  cursor: pointer;

  &:hover {
    background-color: #2676ff;
  }
`;

export const versionId = "1.0.2";
export const UserInfo = () => {
  const dispatch = useDispatch();
  const openUserInfo = useCallback(() => {
    dispatch({
      type: ReduxActionTypes.SET_LAYOUT_ITEM,
      payload: {
        id: "nodeRedId",
        title: "analytic",
        component: "UserA",
      },
    });
  }, []);
  return (
    <div>
      <WrapperCollapse>
        <div
          onClick={openUserInfo}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
        >
          <span style={{ paddingLeft: "14px" }}>Analytic</span>
        </div>
      </WrapperCollapse>
    </div>
  );
};

export const GlobalSidebar = (props?: any, handleCloseServiceTour?: any) => {
  // console.log(props.permission, "permissionpermissionpermission");
  const dispatch = useDispatch();
  const NodeRedRef = useRef<{ close: any }>();
  const IframeRef = useRef<{ close: any }>();
  const DbRef = useRef<{ close: any }>();
  const JSDependenciesRef = useRef<{ close: any }>();
  const user = useSelector((state: any) => getCurrentUser(state));
  const keycloakAccessToken = useSelector(
    (state: AppState) => state.ui.users.currentUser?.keycloakAccessToken,
  );
  const currentApplication = useSelector(
    (state: AppState) => state.ui.applications.currentApplication,
  );
  const currentOrg = useSelector(
    (state: AppState) => state.ui.applications.currentApplication?.orgId,
  );
  const handleClose = useCallback(() => {
    NodeRedRef?.current?.close();
    IframeRef?.current?.close();
    DbRef?.current?.close();
    JSDependenciesRef?.current?.close();
  }, [NodeRedRef]);
  const openModal = () => {
    dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
    const newItem = {
      id: new Date().toISOString(),
      component: "nodeRed",
      title: "New Service",
      props: `https://us.${location.host}/Services%20new`,
      // props: `https://us.platform.eks.ubraine.com/Services%20new`,
    };
    dispatch({
      type: ReduxActionTypes.SET_LAYOUT_ITEM,
      payload: newItem,
    });
    const iframes = document.querySelectorAll("iframe");

    for (let i = 0; i < iframes.length; i++) {
      const iframe = iframes[i];
      const iframeUrl = iframe.getAttribute("src");

      if (
        iframeUrl &&
        iframeUrl?.includes("https://us.platform.ubos.tech/Services%20Copy")
      ) {
        try {
          iframe.setAttribute(
            "src",
            `https://us.platform.ubos.tech/Services%20Copy?access_token=${keycloakAccessToken}&org=${currentOrg}&app=${currentApplication?.id}`,
          );
        } catch (error) {
          console.log(error, "iframe error");
        }
        break; // If you want to reload only the first matching iframe, break the loop.
      }
    }
    // childRef?.current?.openModal();
    // sendUserLogs({
    //   type: "openCreateServices",
    //   userId: user?.id,
    //   userEmail: user?.email,
    // });
  };
  const handleCollapse = async () => {
    dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
  };
  return (
    <WrapperGlobalSidebar>
      <div className="wrapper_header">
        <div className="title">SERVICES</div>
        <div className="wrapper_button_servisec">
          {/* <div
            onClick={handleCollapse}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Update title="Refresh service list"></Update>
          </div> */}
          {props.type_permission.some(
            el =>
              el == "manage:workspace" ||
              el == "manage:organizations" ||
              el == "manage:services",
          ) ? (
            <Tooltip content={"New Service"} usePortal={false} position="right">
              <AddNodeRed className="create-service-btn-tour-step">
                <AddServiceBtn onClick={openModal}>
                  <Icon icon="plus" color="#fff" />
                </AddServiceBtn>
              </AddNodeRed>
            </Tooltip>
          ) : (
            <div style={{ width: "5px" }}></div>
          )}
        </div>
      </div>

      <ServicesSidebar userPermissions={props.type_permission} />
      <IframeCollapse ref={IframeRef} />

      {/* <NodeRedCollapse
        type_permission={props.type_permission}
        ref={NodeRedRef}
        permission={props.permission}
      />
      <IframeCollapse ref={IframeRef} />
      <DbCollapse
        type_permission={props.type_permission}
        permission={props.permission}
        ref={DbRef}
      />
      <UiEditorCollapse
        type_permission={props.type_permission}
        permission={props.permission}
      /> */}
    </WrapperGlobalSidebar>
  );
};

export const literalItemLayoutNew: any = {
  Console: {
    id: createIdConsole(),
    title: "Debugger",
    content: <DebuggerTabs defaultIndex={1} />,
    group: "allowWindow",
    closable: true,
  },
  Sidebar: {
    id: createIdSidebar(),
    title: "Sidebar",
    content: Sidebar,
    group: "allowWindow",
  },
  ContainerEditor: {
    id: createIdEditorPage(),
    title: "UI Editor",
    content: UiEditor,
    group: "allowWindow",
    closable: true,
  },
  EditorPage: {
    id: creatIdEditorPage(),
    title: "Page",
    content: EditorPage,
    group: "allowWindow",
  },
  // globalSidebar: {
  //   id: createIdGlobalSidebar(),
  //   title: "GlobalSidebar",
  //   content: GlobalSidebar,
  //   group: "allowWindow",
  // },
  SettingsWidget: {
    id: createSettingsWidget(),
    title: "Widget",
    content: WrapperSettingsWidget,
    group: "allowWindow",
  },
};

export const defaultLayoutUi: LayoutData = {
  dockbox: {
    mode: "horizontal",
    children: [
      {
        mode: "vertical",
        children: [
          {
            size: 300,
            id: "Sidebar3",
            tabs: [
              {
                id: createIdSidebar(),
                title: "Sidebar",
                content: Sidebar as any,
                group: "allowWindow",
              },
            ],
          },
        ],
      },
      {
        size: 1000,
        id: "WrapperUiEditor",
        tabs: [
          {
            id: creatIdEditorPage(),
            title: "Page",
            content: EditorPage,
            group: "allowWindow",
          },
        ],
      },
      {
        size: 200,
        id: "WrapperSettingsWidget",
        tabs: [
          {
            id: createSettingsWidget(),
            title: "Widget",
            content: WrapperSettingsWidget,
            group: "allowWindow",
            closable: false,
          },
        ],
      },
    ],
  },
};

export const defaultLayout: LayoutData = {
  dockbox: {
    mode: "horizontal",
    id: "MainContainerFull",
    children: [],
  },
};

export const groups = {
  allowWindow: {
    floatable: true,
    newWindow: true,
    maximizable: true,
    closable: true,
  },
};

export function createSettingsWidget() {
  const id = { id: "SettingsWidget", type: "SettingsWidget" };
  return JSON.stringify(id);
}

export function creatIdTransformersMenu() {
  const id = { id: "TransformersMenu", type: "TransformersMenu" };
  return JSON.stringify(id);
}

export function creatIdEditorPage() {
  const id = { id: "EditorPage", type: "EditorPage" };
  return JSON.stringify(id);
}

export function createIdTransformers() {
  const id = { id: "Transformers", type: "Transformers" };
  return JSON.stringify(id);
}

// export function createIdGlobalSidebar() {
//   const id = { id: "globalSidebar", type: "globalSidebar" };
//   return JSON.stringify(id);
// }

export function createIdEditorPage() {
  const id = { id: "ContainerEditor", type: "ContainerEditor" };
  return JSON.stringify(id);
}

export function createIdSidebar() {
  const id = { id: "Sidebar", type: "Sidebar" };
  return JSON.stringify(id);
}

export function createIdTransformer(item: any) {
  const id = { id: item.id, type: "transformer" };
  return JSON.stringify(id);
}

export function createIdWrapperEditorPage() {
  const id = { id: "wrapperEditor", type: "wrapperEditor" };
  return JSON.stringify(id);
}

export function createIdNodeRed(item: any) {
  const id = {
    id: item.title || item.id,
    type: "nodeRed",
    props: item.props,
    queryParams: item?.queryParams,
    icon: item?.icon,
  };
  return JSON.stringify(id);
}
export function createIdLoading(item: any) {
  const id = {
    id: item.title || item.id,
    type: "Loading",
    props: item.props,
    subtype: item.subtype,
  };
  return JSON.stringify(id);
}

export function createIdConsole() {
  const id = { id: "Console", type: "Console" };
  return JSON.stringify(id);
}
export function createIdUserA() {
  const id = { id: "UserInfo", type: "userInfo" };
  return JSON.stringify(id);
}
export function createIdUserPanel() {
  const id = { id: "DeploymenManager", type: "DeploymenManager" };
  return JSON.stringify(id);
}
export function createIdTemplateManager(e?: any) {
  const id = {
    id: "TemplateManager",
    type: "TemplateManager",
    props: e?.props,
  };
  return JSON.stringify(id);
}

export function createIdTemplateManagerV2(e?: any) {
  const id = {
    id: e.id,
    type: "TemplateManagerV2",
    props: e?.props,
  };
  return JSON.stringify(id);
}

export function createIdServiceSetting(e: any) {
  const id = { id: e.props?.id, type: "serviceSetting", props: e.props };
  return JSON.stringify(id);
}
export function createIdAdminPanel() {
  const id = { id: "AdminPanel", type: "AdminPanel" };
  return JSON.stringify(id);
}
export function createIdUserSettings(item: any) {
  const id = {
    id: item.title || item.id,
    type: "UserSettings",
    props: item.props,
  };
  return JSON.stringify(id);
}

export function createIdWorkspaceSettings() {
  const id = { id: "WorkspaceSettings", type: "WorkspaceSettings" };
  return JSON.stringify(id);
}

export function createIdDemoTemplate() {
  const id = { id: "DemoTemplate", type: "DemoTemplate" };
  return JSON.stringify(id);
}

export function createIdUserQuest(item?: any) {
  const id = {
    id: item.title || item.id,
    type: "UserQuest",
    props: item.props,
  };
  return JSON.stringify(id);
}
// export function createIdCustomPage(item: any) {
//   const id = { id: item.title || item.id, type: "nodeRed", props: item.props };
//   return JSON.stringify(id);
// }

export function clearTransformer(item: any) {
  deleteOneItem(item.dockbox);
  deleteOneItem(item.floatbox);
  deleteOneItem(item.maxbox);
  deleteOneItem(item.windowbox);
  return item;
}

export function deleteOneItem(item: any) {
  if (item.children) {
    for (const sub of item.children) {
      if (sub.tabs) {
        const index = [];
        sub.tabs = sub.tabs.filter((item: any) => {
          let inform;
          try {
            inform = JSON.parse(item.id);
          } catch (e) {}
          if (!inform || (inform && inform.type === "transformer")) {
            return false;
          }
          return true;
        });
      } else if (sub.children) {
        deleteOneItem(sub);
      }
    }
  }
  return item;
}

export function savedSettings(layoutRef: any, currentApplicationID: any) {
  if (layoutRef.current) {
    const res = ApplicationApi.updateLayoutApplication(
      currentApplicationID,
      clearTransformer(layoutRef?.current?.saveLayout()),
    );
  }
}

export function savedWidth(currentApplicationID: any, width: number) {
  const res = ApplicationApi.updateLayoutSidebarWidth(currentApplicationID, {
    sidebarWidth: width,
  });
}

export function savedSettingsUi(layoutRef: any, currentApplicationID: any) {
  const res = ApplicationApi.updateUiLayoutApplication(
    currentApplicationID,
    clearTransformer(layoutRef?.current?.saveLayout()),
  );
}

function findOneItem(item: any) {
  if (item.children) {
    for (const sub of item.children) {
      if (sub.tabs) {
        for (const tab of sub.tabs) {
          let inform;
          try {
            inform = JSON.parse(tab.id);
          } catch (e) {
            inform = { type: "transform" };
          }

          if (literalItemLayoutNew[inform.type]) {
            for (const key in literalItemLayoutNew[inform.type]) {
              tab[key] = literalItemLayoutNew[inform.type][key];
            }
          }
          if (sub.x < 0) {
            sub.w = 500;
            sub.x = 10;
          }
          if (sub.y < 0) {
            sub.h = 500;
            sub.y = 50;
          }
          if (sub.x > window.innerWidth - 10) {
            sub.w = 500;
            sub.x = 10;
          }
          if (sub.y > window.innerHeight - 10) {
            sub.h = 500;
            sub.y = 50;
          }

          if (literalItemLayoutCreate[inform.type]) {
            const functionCreate = literalItemLayoutCreate[inform.type](inform);
            for (const key in functionCreate) {
              tab[key] = functionCreate[key];
            }
          }
        }
      } else if (sub.children) {
        findOneItem(sub);
      }
    }
  }
  return item;
}

const literalItemLayoutCreate: any = {
  nodeRed: (e: any) => {
    console.log(e, "eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
    return {
      id: createIdNodeRed(e),
      content: <NodeRed url={e.props} queryParams={e?.queryParams} />,
      // title: e.id,
      title: (
        <CustomTitleTabElement title={e.id} url={e.props} icon={e?.icon} />
      ),
      group: "allowWindow",
      closable: e.id == "Get Started" ? false : true,
      // closable: true
    };
  },
  serviceSetting: (e: any) => {
    return {
      id: createIdServiceSetting(e),
      content: <ServiceSettingU props={e.props} />,
      // title: `ServiceSetting ${e.props.name}`,
      title: (
        <CustomTitleTabElement
          title={`Service Setting ${e.props.name}`}
          url={getURLForCustomTitleTab("ServiceSetting")}
          icon={InfoIcon}
        />
      ),
      group: "allowWindow",
      closable: true,
    };
  },
  transformer: (e: any) => {
    return {
      id: "delete",
      closable: true,
    };
  },
  userInfo: () => {
    return {
      id: createIdUserA(),
      content: <UserA />,
      title: "analytic",
      group: "allowWindow",
      closable: true,
    };
  },
  AdminPanel: () => {
    return {
      id: createIdAdminPanel(),
      content: <AdminPanelU />,
      // title: "System Services",
      title: (
        <CustomTitleTabElement
          title={"System Services"}
          url={getURLForCustomTitleTab("SystemServices")}
        />
      ),
      group: "allowWindow",
      closable: true,
    };
  },
  DeploymenManager: () => {
    return {
      id: createIdUserPanel(),
      content: <UserPanelU />,
      // title: "Admin Panel",
      title: (
        <CustomTitleTabElement
          title={"Admin Panel"}
          url={getURLForCustomTitleTab("DeploymenManager")}
        />
      ),
      group: "allowWindow",
      closable: true,
    };
  },
  TemplateManager: () => {
    return {
      id: createIdTemplateManager(),
      content: <TemplateManagerU />,
      // title: "Template Manager",
      title: (
        <CustomTitleTabElement
          title={"Asset Marketplace"}
          url={getURLForCustomTitleTab("TemplateManager")}
          icon={TemplateIcon}
        />
      ),
      // title: <CustomTitleTabElement title={`TemplateManager`} url={e.props} />,
      group: "allowWindow",
      closable: true,
    };
  },
  TemplateManagerV2: (e: any) => {
    return {
      id: createIdTemplateManagerV2(e),
      content: <TemplateManagerUV2 options={e?.props} />,
      title: (
        <CustomTitleTabElement
          title={e?.props?.title ? e?.props.title : "Asset Marketplace"}
          url={e?.props?.url}
          icon={TemplateIcon}
        />
      ),
      // title: <CustomTitleTabElement title={`TemplateManager`} url={e.props} />,
      group: "allowWindow",
      closable: true,
    };
  },
  UserSettings: (e: any) => {
    return {
      id: createIdUserSettings(e),
      content: <UserSettings options={e.props} />,
      // title: e.id || e.type,
      title: (
        <CustomTitleTabElement
          title={e.id || e.type}
          url={getURLForCustomTitleTab("UserSettings")}
        />
      ),
      group: "allowWindow",
      closable: true,
    };
  },

  UserQuest: (e: any) => {
    return {
      id: createIdUserQuest(e),
      content: <UserQuest options={e.props} />,
      // title: e.id || e.type,
      title: (
        <CustomTitleTabElement
          title={e.id || e.type}
          url={getURLForCustomTitleTab("UserQuest")}
          icon={GameIcon}
        />
      ),
      group: "allowWindow",
      closable: true,
    };
  },
  WorkspaceSettings: (e?: any) => {
    return {
      id: createIdWorkspaceSettings(),
      content: <WorkspaceSetting />,
      // title: "Workspace Setting",
      title: (
        <CustomTitleTabElement
          title={"Workspace Setting"}
          url={getURLForCustomTitleTab("WorkspaceSetting")}
          icon={WorkspaceIcon}
        />
      ),
      group: "allowWindow",
      closable: true,
    };
  },

  DemoTemplate: (e?: any) => {
    // const user = useSelector((state: any) => getCurrentUser(state));

    return {
      id: createIdDemoTemplate(),
      content: <DemoTemplate />,
      // title: "AI Chat",
      title: (
        <CustomTitleTabElement
          title={"AI Chat"}
          url={"https://aibotui-8988-658ad621387fb711000001e1.ubos.tech"}
        />
      ),
      // title: (
      //   <CustomTitleTabElement
      //     title={"AI Bot"}
      //     url={getURLForCustomTitleTab("DemoTemplate", {
      //       userEmail: user?.email,
      //     })}
      //   />
      // ),
      group: "allowWindow",
      closable: true,
    };
  },
  customPage: (e: any) => {
    return {
      id: e.id,
      content: <CustomPage />,
      title: e.id,
      group: "allowWindow",
      closable: true,
    };
  },
  Loading: (e: any) => {
    return {
      id: createIdLoading(e),
      content: (
        <LoadingItem
          subtype={e.subtype}
          url={e.props}
          type={"Loading"}
          id={createIdLoading(e)}
          title={e.id}
        />
      ),
      title: e.id,
      group: "allowWindow",
      closable: true,
    };
  },
};

export function replaceItemDockLayout(item: any) {
  if (item) {
    const floatbox = findOneItem(item.floatbox);
    const windowbox = findOneItem(item.windowbox);
    if (floatbox.children.length > 0 && windowbox.children.length > 0) {
      floatbox.children[0].tabs.push();
      floatbox.children[0].tabs = [
        ...floatbox.children[0].tabs,
        ...windowbox.children[0].tabs,
      ];
    } else if (
      floatbox.children.length === 0 &&
      windowbox.children.length > 0
    ) {
      floatbox.children = windowbox.children;
    }
    const content = {
      dockbox: findOneItem(item.dockbox),
      floatbox: floatbox,
      maxbox: findOneItem(item.maxbox),
    };
    return content;
  } else {
    return false;
  }
}

interface ICustomTitleTabElement {
  title: string;
  url: any;
  icon?: string | React.FunctionComponent<any>;
}

export const CustomTitleTabElement = (props: ICustomTitleTabElement) => {
  const Flex = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;

    & button {
      position: initial !important;
      display: flex;
      align-items: center;
      justify-content: center;
      /* position: relative !important; */
      /* height: 10px; */
      border: none;
      background: none;
      /* right: 0 !important;
      left: 6px !important;
      top: 2px !important; */
      transition: all 0.25s ease-in-out;

      &::before {
        display: none;
      }

      padding: 0;
      cursor: pointer;

      :hover {
        & svg {
          .refresh-service-icon-svg path {
            stroke: #000;
          }
        }
      }
    }
  `;

  const IconWrapper = styled.div`
    display: flex;
    align-items: center;

    > svg {
      width: 15px;
      height: 100%;
      fill: #3b82ff;
    }

    > img {
      width: 15px;
    }
  `;

  const handleReload = () => {
    const iframes = document.querySelectorAll("iframe");

    for (let i = 0; i < iframes.length; i++) {
      const iframe = iframes[i];
      const iframeUrl = iframe.getAttribute("src");

      if (iframeUrl && iframeUrl?.includes(props.url)) {
        try {
          iframe.setAttribute("src", iframeUrl);
        } catch (error) {
          console.log(error, "iframe error");
        }
        break; // If you want to reload only the first matching iframe, break the loop.
      }
    }
  };

  function truncateString(str: string) {
    if (str.length > 18) {
      return str.substring(0, 18) + "...";
    } else {
      return str;
    }
  }

  const myString = props.title;
  const truncatedString = truncateString(myString);

  const RefreshBtn = styled.button`
    opacity: 0;
  `;

  return (
    <Flex className="custom-icon-wrapp">
      <IconWrapper>
        {props.icon ? (
          typeof props.icon === "string" && props.icon.startsWith("http") ? (
            <img src={props.icon} alt="Service icon" />
          ) : (
            <props.icon />
          )
        ) : (
          <MainLogo></MainLogo>
        )}
      </IconWrapper>
      <span
        title={props.title}
        style={{ fontSize: "12px", fontFamily: "Roboto" }}
      >
        {truncatedString}
      </span>

      <RefreshBtn
        className="dock-tab-refresh-btn"
        onClick={() => handleReload()}
        title="Refresh page"
      >
        <svg
          width="11px"
          height="11px"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          stroke="#6C767E"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g className="refresh-service-icon-svg">
            <path
              d="M4.06189 13C4.02104 12.6724 4 12.3387 4 12C4 7.58172 7.58172 4 12 4C14.5006 4 16.7332 5.14727 18.2002 6.94416M19.9381 11C19.979 11.3276 20 11.6613 20 12C20 16.4183 16.4183 20 12 20C9.61061 20 7.46589 18.9525 6 17.2916M9 17H6V17.2916M18.2002 4V6.94416M18.2002 6.94416V6.99993L15.2002 7M6 20V17.2916"
              stroke="#6C767E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </g>
        </svg>
      </RefreshBtn>
    </Flex>
  );
};

export const NodeRed = (props: { url: string; queryParams?: any }) => {
  const keycloakAccessToken = useSelector(
    (state: AppState) => state.ui.users.currentUser?.keycloakAccessToken,
  );
  const currentApplication = useSelector(
    (state: AppState) => state.ui.applications.currentApplication,
  );
  const currentOrg = useSelector(
    (state: AppState) => state.ui.applications.currentApplication?.orgId,
  );
  const user = useSelector((state: AppState) => state.ui.users.currentUser);

  // const userData = useSelector((state: AppState) => state.ui.users.currentUser);

  // const baseQueryParams = `?access_token=${keycloakAccessToken}&orgId=${currentOrg}&app=${currentApplication?.id}`;
  const templateURL = `https://template-manager.${location.host}/all_templates`; // added query parameters from UI

  let mainURL = `${props.url}?access_token=${keycloakAccessToken}&org=${currentOrg}&app=${currentApplication?.id}&orgId=${currentOrg}&userId=${user?.id}&userEmail=${user?.email}`;
  const mainURLWithTemplateURL = `${mainURL}&templateURL=${templateURL}`;

  if (props.queryParams !== undefined) {
    mainURL = Object.keys(props.queryParams).reduce((url, key) => {
      return `${url}&${key}=${encodeURIComponent(props.queryParams[key])}`;
    }, mainURL);
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe
        src={props.url.includes("StartPage") ? mainURLWithTemplateURL : mainURL}
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};

export const UserA = () => {
  const user_namespace = useSelector(
    (state: AppState) => state.ui.users.currentUser?.user_namespace,
  );
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe
        src={"https://admin.ubraine.com/" + "?user_namespace=" + user_namespace}
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};
export const UserPanelU = () => {
  const keycloakAccessToken = useSelector(
    (state: AppState) => state.ui.users.currentUser?.keycloakAccessToken,
  );
  const org_id = useSelector((state: AppState) => state.ui.orgs.currentOrg.id);
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe
        src={`https://deployment-manager.${location.host}/home?access_token=${keycloakAccessToken}&orgId=${org_id}`}
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};

interface ITemplateManagerUV2 {
  options: {
    url: string;
    queryParams?: any;
  };
}

export const TemplateManagerUV2 = ({ options }: ITemplateManagerUV2) => {
  const url = options?.url;
  const queryParams = options?.queryParams;

  const keycloakAccessToken = useSelector(
    (state: AppState) => state.ui.users.currentUser?.keycloakAccessToken,
  );
  const currentApplication = useSelector(
    (state: AppState) => state.ui.applications.currentApplication,
  );
  const currentOrg = useSelector(
    (state: AppState) => state.ui.applications.currentApplication?.orgId,
  );
  const user = useSelector((state: AppState) => state.ui.users.currentUser);
  let mainURL = `${url}?access_token=${keycloakAccessToken}&org=${currentOrg}&app=${currentApplication?.id}&orgId=${currentOrg}&userId=${user?.id}&userEmail=${user?.email}`;

  if (queryParams !== undefined) {
    mainURL = Object.keys(queryParams).reduce((url, key) => {
      return `${url}&${key}=${encodeURIComponent(queryParams[key])}`;
    }, mainURL);
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe src={mainURL} width="100%" height="100%"></iframe>
    </div>
  );
};

interface ITemplateManagerU {
  options?: {
    navigateTo?: string;
    templateId?: string;
  };
}
export const TemplateManagerU = ({ options }: ITemplateManagerU) => {
  const getLocation = useLocation();
  const queryParams = new URLSearchParams(getLocation.search);
  const templateId = queryParams.get(QUERY_KEY_TEMPLATE_ID);

  const keycloakAccessToken = useSelector(
    (state: AppState) => state.ui.users.currentUser?.keycloakAccessToken,
  );
  const currentApplication = useSelector(
    (state: AppState) => state.ui.applications.currentApplication,
  );
  const org_id = useSelector((state: AppState) => state.ui.orgs.currentOrg.id);
  const user = useSelector((state: AppState) => state.ui.users.currentUser);

  const baseURL = `https://template-manager.${location.host}`;
  // const baseURL = `https://template-manager.platform.ubos.tech`;

  const baseQueryParams = `?access_token=${keycloakAccessToken}&org=${org_id}&orgId=${org_id}&app=${currentApplication?.id}&userId=${user?.id}&userEmail=${user?.email}`;

  let mainURL = `https://ubos.tech/templates/${baseQueryParams}`;

  // if (options?.navigateTo === "success_fork_template") {
  //   mainURL = `${baseURL}/success_fork_template/${baseQueryParams}&templateId=${options.templateId}`;
  // }

  if (options?.navigateTo === "server_configuration") {
    mainURL = `${baseURL}/server_configuration/${baseQueryParams}&templateId=${options.templateId}`;
  }

  if (options?.templateId || templateId) {
    mainURL = `${baseURL}/server_configuration/${baseQueryParams}&templateId=${templateId ||
      options?.templateId}`;
  }

  // if (options?.templateId || templateId) {
  //   mainURL = `${baseURL}/template_info/${baseQueryParams}&template_id=${templateId ||
  //     options?.templateId}`;
  // }

  // const mainURL = templateId
  //   ? `${baseURL}/template_info/${baseQueryParams}&template_id=${templateId}`
  //   : `${baseURL}/all_templates/${baseQueryParams}`;

  const templateIdFromCookie = document.cookie.replace(
    /(?:(?:^|.*;\s*)templateId\s*\=\s*([^;]*).*$)|^.*$/,
    "$1",
  );

  if (templateIdFromCookie) {
    console.log("REMOVE TEMPLATE ID FROM Cookie");

    document.cookie = `${QUERY_KEY_TEMPLATE_ID}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe src={mainURL} width="100%" height="100%"></iframe>
    </div>
  );
};
export const ServiceSettingU = (props: any) => {
  const keycloakAccessToken = useSelector(
    (state: AppState) => state.ui.users.currentUser?.keycloakAccessToken,
  );
  const org_id = useSelector((state: AppState) => state.ui.orgs.currentOrg.id);
  const user = useSelector((state: AppState) => state.ui.users.currentUser);
  const datasourcesList: any = useSelector(
    (state: AppState) => state.entities.datasources.list,
  );

  const datasourceId = props.props.id;
  // const datasourceInfo = datasourcesList?.find(
  //   el => el.datasources[0].id == datasourceId,
  // );

  const datasourceInfo = datasourcesList
    ?.flatMap(el => el.datasources)
    .find(el => el.id === datasourceId);

  const subscription_id = props.props.subscription_id;
  let url = `https://service-setting.${location.host}/Overview?access_token=${keycloakAccessToken}&orgId=${org_id}&subscription_id=${subscription_id}&datasourceId=${datasourceId}&userId=${user?.id}&userEmail=${user?.email}`;
  if (datasourceInfo && datasourceInfo?.version == 2) {
    url = `https://service-setting.${location.host}/Overview%20New?access_token=${keycloakAccessToken}&orgId=${org_id}&subscription_id=${subscription_id}&datasourceId=${datasourceId}&userId=${user?.id}&userEmail=${user?.email}`;
    // const url = `https://service-setting.platform.eks.ubraine.com/Overview%20New?access_token=${keycloakAccessToken}&orgId=${org_id}&subscription_id=${subscription_id}&datasourceId=${datasourceId}&userId=${user?.id}&userEmail=${user?.email}`;
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe
        id="serviceIdIframe"
        src={url}
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};
export const WorkspaceSetting = (props: any) => {
  const keycloakAccessToken = useSelector(
    (state: AppState) => state.ui.users.currentUser?.keycloakAccessToken,
  );
  const org_id = useSelector((state: AppState) => state.ui.orgs.currentOrg.id);
  const user = useSelector((state: AppState) => state.ui.users.currentUser);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe
        id="workspaceIdIframe"
        src={`https://service-setting.${location.host}/Server?access_token=${keycloakAccessToken}&orgId=${org_id}&userId=${user?.id}&userEmail=${user?.email}`}
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};

interface IUserQuest {
  options?: {
    navigateTo?: string;
    questId?: string;
  };
}

export const UserQuest = ({ options }: IUserQuest) => {
  const keycloakAccessToken = useSelector(
    (state: AppState) => state.ui.users.currentUser?.keycloakAccessToken,
  );
  const userId = useSelector(
    (state: AppState) => state.ui.users.currentUser?.id,
  );

  let navigateTo = "Quests";
  const questId = options?.questId;

  if (options?.navigateTo) navigateTo = options?.navigateTo;

  const host = location.host; // location.host || platform.ubos.tech

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe
        id="workspaceIdIframe"
        src={`https://user-quest.${host}/${navigateTo}?access_token=${keycloakAccessToken}&questId=${questId}&userId=${userId}`}
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};

export const DemoTemplate = ({ options }: any) => {
  // console.log(options, "asdsaddsadasdasasd");

  const userEmail = useSelector(
    (state: AppState) => state.ui.users.currentUser?.email,
  );

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe
        id="demo-template-id-iframe"
        src={`https://aibotui-8988-658ad621387fb711000001e1.ubos.tech/Login?email=${userEmail}`}
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};

export const AdminPanelU = () => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe
        src={`https://sys-adm.${location.host}/`}
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};

interface IUserSettings {
  options?: {
    navigateTo?: string;
  };
}

export const UserSettings = ({ options }: IUserSettings) => {
  const keycloakAccessToken = useSelector(
    (state: AppState) => state.ui.users.currentUser?.keycloakAccessToken,
  );

  const currentApplication = useSelector(
    (state: AppState) => state.ui.applications.currentApplication,
  );

  const user = useSelector((state: AppState) => state.ui.users.currentUser);

  const baseURL = `https://us.${location.host}`;
  // const baseURL = `https://us.platform.ubos.tech`;
  const baseQueryParams = `access_token=${keycloakAccessToken}&orgId=${currentApplication?.organizationId}&org=${currentApplication?.organizationId}&app=${currentApplication?.id}&userId=${user?.id}&userEmail=${user?.email}`;

  let mainURL = `${baseURL}/General?${baseQueryParams}`;

  if (options?.navigateTo === "Load") {
    mainURL = `${baseURL}/Load?${baseQueryParams}`;
  }
  if (options?.navigateTo === "Credits") {
    mainURL = `${baseURL}/Credits?${baseQueryParams}`;
  }
  if (options?.navigateTo === "Services") {
    mainURL = `https://service-setting.${location.host}/Services?${baseQueryParams}`;
  }
  if (options?.navigateTo === "Your Servers") {
    mainURL = `${baseURL}/Your%20Servers?${baseQueryParams}`;
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe
        src={mainURL}
        // src={`https://us.${location.host}/General?access_token=${keycloakAccessToken}&app=${currentApplication?.id}`}
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};
export const CustomPage = (props: any) => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe src={props} width="100%" height="100%"></iframe>
    </div>
  );
};

export const LoadingItem = (props: any) => {
  const dispatch = useDispatch();
  const test = {
    id: props.id,
    content: (
      <LoadingItem
        url={props.props}
        type={"Loading"}
        id={createIdLoading(props)}
        title={props.id || props.name}
      />
    ),
    title: props.title,
    group: "allowWindow",
    closable: true,
  };
  dispatch({
    type: ReduxActionTypes.UPDATE_LAYOUT_ITEM,
    payload: test,
  });
  return (
    <div style={{ width: "100%", height: "100%" }}>
      {/* <UbosLoader /> */}
      <UbosLoader2 />
      {/* <span>Loading...</span> */}
    </div>
  );
};

export const ItemTransformer = (props: any) => {
  const item: any = useSelector((state: AppState) => {
    return getTransformer(state).find(items => {
      return items.widgetId == props.item.widgetId;
    });
  });

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {!item?.typeFunction ? (
        <>
          <div style={{ height: "65px" }}>
            <TransformEditor
              property={"expectation"}
              height={"35px"}
              title={"Dependent fields"}
              value={item?.expectation}
              items={item}
            />
          </div>
          <div style={{ height: "165px" }}>
            <TransformEditor
              property={"text"}
              height={"153px"}
              title={"Functions"}
              value={item?.text}
              items={item}
            />
          </div>
        </>
      ) : (
        <TransformEditorJS
          property={"text"}
          height={100}
          title={"Transformers"}
          value={item?.text}
          items={item}
        />
      )}
    </div>
  );
};
